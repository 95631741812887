.collection{
    padding: 30px;
  border-radius: 8px;
  background: white;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  color: #222730;
  gap: 10px;

  &_title{
    color: #222730;
    font-size: 24px;
    font-weight: 500;
  }
  &_status{
    color: #222730;
    font-size: 24px;
    font-weight: 500;
  }
}
.table {
	width: 100%;
	margin-bottom: 20px;
	border: 1px solid #dddddd;
  color: #66728a;
	border-collapse: collapse; 
}
.table th {
	font-weight: bold;
	padding: 5px;
	background: #efefef;
    padding: 10px;
	border: 1px solid #dddddd;
}
.table tr {
    text-align: center;
    align-items: center;
}
.table td {
    font-size: 14px;
	border: 1px solid #dddddd;
	padding: 10px;
}