@import "../../scss";

.main {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.header {
  background: white;
  width: 100%;
  min-height: 60px;
  border-bottom: 1px solid $nord4;
  .headerContainer {
    max-width: 1620px;
    padding: 0 20px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100%;
    .title {
      font-size: 1.25rem;
      color: $x-nord1;
      font-weight: 900;
      letter-spacing: 0.03rem;
    }
    .tools {
      display: flex;
      align-items: center;
      height: 100%;
      .profileDropdown {
        position: relative;
        height: 100%;
        .profileDropdown__label {
          cursor: pointer;
          position: relative;
          height: 100%;
          padding: 0 10px;
          min-width: 100px;
          transition: 100ms linear;
          display: flex;
          align-items: center;
          justify-content: center;
          z-index: 101;
          color: $nord1;
          &:hover {
            background: $nord6;
          }
          &:active {
            background: $nord4;
          }
        }
        .profileDropdown__menu {
          top: 100%;
          right: 0;
          border-radius: 6px 0 6px 6px;
          padding: 8px 0;
          position: absolute;
          background: $nord9;
          z-index: 100;
          width: 250px;
          transform-origin: top right;
          display: flex;
          flex-direction: column;
          &.enter {
            opacity: 0;
            transform: scale(0.8);
          }
          &.enterActive {
            opacity: 1;
            transition: 80ms linear;
            transform: scale(1);
          }
          &.exit {
            opacity: 1;
            transform: scale(1);
          }
          &.exitActive {
            opacity: 0;
            transition: 80ms linear;
            transform: scale(0.8);
          }
        }
        .profileDropdown__item {
          cursor: pointer;
          width: 100%;
          padding: 10px 14px;
          transition: 80ms linear;
          text-align: left;
          color: white;
          &:hover {
            background: darken($nord9, 7%);
          }
          &:active {
            background: darken($nord9, 12%);
          }
        }
        &.active {
          z-index: 1000;
          .profileDropdown__label {
            color: white;
            background: $nord9;
            &:hover {
              background: $nord9;
            }
          }
        }
        &.small {
          .profileDropdown__menu {
            width: fit-content;
            min-width: 150px;
          }
        }
      }
    }
  }
}

.cover {
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  z-index: 100;
}
