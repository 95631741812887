@import "../../scss";

.input {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 4px;
  &.error {
    .legend {
      color: $nord11;
    }
    .short_text__field {
      border-color: $nord11;
    }
    .select__label {
      border-color: $nord11;
    }
  }
}
.error_text {
  color: $nord11;
  font-size: 0.75rem;
  max-width: 90%;
}
.legend {
  color: $x-nord1;
  font-size: 0.875rem;
  padding: 0 11px;
}

.short_text {
  background: white;
  width: 100%;
  height: 42px;
  display: flex;
  align-items: stretch;
  position: relative;
  .short_text__field {
    width: 100%;
    height: 100%;
    border: 1px solid $nord4;
    border-radius: 4px;
    transition: 80ms linear;
    padding: 0 11px;
    font-size: 0.9375rem;
    color: $nord3;
    &:hover {
      border-color: lighten($nord10, 20%);
    }
    &:focus {
      border-color: $nord10;
    }
    &::placeholder {
      color: lighten($x-nord1, 5%);
    }
    &.with_arrows {
      padding-right: 49px;
    }
    &.with_visibility_button {
      padding-right: 46px;
    }
  }
  .visibility_button {
    position: absolute;
    right: 11px;
    top: 50%;
    transform: translateY(-50%);
    color: rgba($x-nord1, 0.5);
    cursor: pointer;
  }
  .short_text__arrows {
    display: flex;
    flex-direction: column;
    width: 38px;
    height: calc(100% - 2px);
    position: absolute;
    top: 1px;
    right: 1px;
    overflow: hidden;
  }
  .arrow__top,
  .arrow__bottom {
    cursor: pointer;
    width: 100%;
    flex-grow: 1;
    display: flex;
    border-left: 1px solid $nord4;
    align-items: center;
    justify-content: center;
    transition: 80ms linear;
    color: $nord3;
    &:hover {
      background: $nord6;
    }
    &:active {
      background: $nord5;
    }
    &.disabled {
      color: rgba($nord3, 0.4);
      pointer-events: none;
    }
  }
  .arrow__top {
    border-bottom: 1px solid $nord4;
  }
}

.select {
  position: relative;
  .select__label {
    cursor: pointer;
    width: 100%;
    border: 1px solid $nord4;
    border-radius: 4px;
    padding-left: 11px;
    height: 42px;
    display: flex;
    font-size: 0.9375rem;
    justify-content: space-between;
    align-items: center;
    transition: 80ms linear;
    color: $nord3;
    .placeholder {
      color: lighten($x-nord1, 5%);
      font-size: 0.9375rem;
    }
    &:hover {
      border-color: lighten($nord10, 20%);
    }
    &:active {
      border-color: $nord10;
    }
  }
  .select__arrow {
    width: 38px;
    height: 100%;
    color: $nord3;
    transition: 80ms linear;
    border-left: 1px solid $nord4;
    svg {
      transition: 80ms linear;
    }
  }

  .select__options__enter {
    opacity: 0;
    transform: scaleY(0);
  }
  .select__options__enter_active {
    opacity: 1;
    transition: 80ms ease-out;
    transform: scaleY(1);
  }
  .select__options__exit {
    opacity: 1;
    transform: scaleY(1);
  }
  .select__options__exit_active {
    opacity: 0;
    transition: 80ms ease-out;
    transform: scaleY(0);
  }

  .select__options {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    padding: 8px 0;
    background: white;
    box-shadow: 0 2px 15px rgba($nord0, 0.2);
    transform-origin: top center;
    border-radius: 4px;
    max-height: 250px;
    overflow-y: auto;
    z-index: 30;
    &.withSearchText {
      padding-top: 0;
    }
  }
  .select__option {
    cursor: pointer;
    width: 100%;
    padding: 12px 8px;
    transition: 80ms linear;
    text-align: left;
    color: $x-nord1;
    font-size: 0.875rem;
    &:hover {
      background: $nord6;
    }
    &:active {
      background: $nord5;
    }
  }
  &.opened {
    .select__arrow {
      svg {
        transform: rotateZ(180deg);
      }
    }
  }

  .selectFilterTextWrap {
    display: flex;
    position: sticky;
    padding: 8px 8px;
    padding-bottom: 6px;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 10;
    background: white;
    .selectFilterText {
      padding: 0 8px;
      height: 34px;
      border-radius: 4px;
      border: 1px solid $nord4;
      width: 100%;
      font-size: 0.875rem;
      &:focus {
        border-color: $nord10;
      }
      &::placeholder {
        font-size: 0.875rem;
        color: lighten($x-nord1, 10%);
      }
    }
  }
}

.date {
  cursor: pointer;
  width: 100%;
  height: 42px;
  border-radius: 4px;
  border: 1px solid $nord4;
  text-align: left;
  padding: 0 11px;
  font-size: 0.9375rem;
  color: $nord3;
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: 80ms linear;
  &:hover {
    border-color: lighten($nord10, 20%);
  }
  &:active {
    border-color: $nord10;
  }
}
