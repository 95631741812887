@import "../../../scss";

.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  width: 100%;
  height: 100dvh;
  padding: 150px 0;
  background: rgba(#000, 0.6);
  overflow-y: auto;
  backdrop-filter: blur(1px);
  &.modalEnter {
    opacity: 0;
    .modalWrap {
      transform: scale(0.9) translateY(50px);
    }
  }
  &.modalEnterActive {
    transition: 150ms ease-in-out;
    opacity: 1;
    .modalWrap {
      transition: 150ms ease-in-out;
      transform: scale(1) translateY(0);
    }
  }
  &.modalExit {
    opacity: 1;
    .modalWrap {
      transform: scale(1) translateY(0);
    }
  }
  &.modalExitActive {
    transition: 150ms ease-in-out;
    opacity: 0;
    .modalWrap {
      transition: 150ms ease-in-out;
      transform: scale(0.9) translateY(50px);
    }
  }
  .modalWrap {
    margin: 0 auto;
    background: white;
    border-radius: 8px;
    width: 380px;
    padding: 24px 24px;
    display: flex;
    flex-direction: column;
    gap: 30px;
    .title {
      font-size: 1.25rem;
      font-weight: 600;
      color: $nord1;
    }
    .recommendations {
      display: flex;
      flex-direction: column;
      gap: 14px;
      .recommendation {
        cursor: pointer;
        padding: 20px 14px 14px 14px;
        border-radius: 8px;
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 10px;
        border: 1px solid #ccc;
        position: relative;
        transition: 100ms linear;
        .smallTitle {
          position: absolute;
          font-size: 0.75rem;
          color: $x-nord2;
          top: 10px;
          left: 50%;
          transform: translateX(-50%);
        }
        .item {
          display: flex;
          justify-content: space-between;
          align-items: center;
          width: 100%;
          .key {
            color: $x-nord1;
            text-align: left;
          }
          .value {
            color: $x-nord1;
            text-align: right;
          }
        }
        &:hover {
          background: $nord6;
          border-color: $nord6;
        }
        &.selected {
          background: $nord10;
          border-color: $nord10;
          .smallTitle {
            color: #fff;
          }
          .key {
            color: #fff;
          }
          .value {
            color: #fff;
          }
        }
      }
    }
    .buttonGroup {
      display: flex;
      justify-content: flex-end;
      gap: 14px;
    }
  }
}
